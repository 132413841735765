<template>
  <div>
    <sm-editable-item
      v-model="form"
      :validatePermission="false"
      :disabledAllFields="true"
      :isLoading="isLoadingPage"
      :breadcrumbs="breadcrumbs"
      :pageHeader="pageHeader"
      :fields="tableFields"
      @cancel="onCancel"
    >
      <template #tab-top-panel-0>
        <sm-editable-form
          v-model="form"
          :fields="formFields[0].form"
          :disabledAllFields="true"
        />
        <div class="deploy-detail__add-release-wrapper">
          <sm-button class="deploy-detail__add-release" @click="onAddRelease"
            >Добавить</sm-button
          >
        </div>
      </template>

      <template v-slot:table-releases-releaseInfo="{ row }">
        <router-link
          class="editable-item__link"
          :to="{ name: 'ReleaseInfo', params: { id: row.id, deployId: id } }"
        >
          Просмотреть
        </router-link>
      </template>
    </sm-editable-item>

    <sm-form-modal
      v-model="modalForm"
      :key="addReleaseModalKey"
      :fields="modalFields"
      :show="showAddReleaseModal"
      modal-title="Добавление релиза"
      :isLoadingSaveButton="isLoadingAddRelease"
      @changeFile="onChangeFile"
      @removeFile="onRemoveFile"
      @close="onCancelAddRelease"
      @save="onConfirmAddRelease"
      @cancel="onCancelAddRelease"
    />
  </div>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';
import SmEditableForm from '@/components/common/forms/SmEditableForm.vue';

export default {
  name: 'DeployDetail',

  components: {
    SmEditableItem,
    SmButton,
    SmFormModal,
    SmEditableForm,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      refreshPageInterval: null,
      form: {},
      showAddReleaseModal: false,
      addReleaseModalKey: 0,
      modalForm: {
        file: null,
      },
      isLoadingAddRelease: false,
    };
  },

  computed: {
    ...mapState({ projectInfo: (state) => state.deploy.projectInfo }),

    breadcrumbs() {
      return [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Проекты',
          route: { name: 'Deploy' },
        },
        {
          text: this.pageHeader,
        },
      ];
    },

    releases() {
      const { releases } = this.form;

      if (releases) {
        return releases.sort((a, b) => b.id - a.id);
      }

      return [];
    },

    pageHeader() {
      return `${this.form.name}`;
    },

    formFields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
            {
              type: 'text',
              key: 'lastReleaseCreated',
              label: 'Дата добавления последнего релиза',
            },
            {
              type: 'text',
              key: 'lastReleaseAuthor',
              label: 'Автор последний релиз',
            },
            {
              type: 'text',
              key: 'releaseCount',
              label: 'Количество добавленных релизов',
            },
          ],
        },
      ];
    },

    tableFields() {
      return [
        {
          table: {
            caption: 'Релизы',
            headers: [
              {
                text: '№',
                alias: 'id',
              },
              {
                text: 'Дата добавления',
                alias: 'created',
              },
              {
                text: 'Автор',
                alias: 'author',
              },
              {
                text: 'Статус публикации',
                alias: 'status',
              },
              {
                text: 'Информация о релизе',
                alias: 'releaseInfo',
              },
            ],
            items: this.releases,
            key: 'releases',
          },
        },
      ];
    },

    modalFields() {
      return [
        {
          type: 'file',
          key: 'file',
          label: 'Файл релиза (zip архив)',
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    this.fetchProjectInfo().finally(() => {
      this.isLoadingPage = false;
    });
    this.refreshPageInterval = setInterval(this.fetchProjectInfo, 10000);
  },

  beforeDestroy() {
    clearInterval(this.refreshPageInterval);
  },

  methods: {
    ...mapActions({
      getProjectInfo: 'deploy/getProjectInfo',
      addRelease: 'deploy/addRelease',
      addFile: 'pendingFiles/addFile',
      removeFiles: 'pendingFiles/removeFiles',
    }),

    fetchProjectInfo() {
      return this.getProjectInfo({ projectId: this.id }).then(() => {
        this.form = this.lodash.cloneDeep(this.projectInfo.data);
      });
    },

    onAddRelease() {
      this.showAddReleaseModal = true;
    },

    onConfirmAddRelease() {
      this.isLoadingAddRelease = true;

      const FormData = require('form-data');
      const fd = new FormData();
      fd.append('projectId', this.form.id);
      fd.append('file', this.modalForm.file, this.modalForm.file.name);

      this.addRelease(fd)
        .then(({ isSucceed }) => {
          if (isSucceed) {
            this.$notify({
              header: 'Релиз успешно добавлен',
              type: 'success',
              timer: 5000,
            });

            this.onCancelAddRelease();
            this.fetchProjectInfo();
          }
        })
        .finally(() => {
          this.isLoadingAddRelease = false;
        });
    },

    onCancelAddRelease() {
      this.showAddReleaseModal = false;
      this.onRemoveFile();
      this.addReleaseModalKey += 1;
    },

    onChangeFile({ file }) {
      this.modalForm.file = file[0];
    },

    onRemoveFile() {
      this.modalForm.file = null;
    },

    onCancel() {
      this.$router.push({ name: 'Deploy' });
    },
  },
};
</script>

<style lang="scss">
.editable-item__link {
  text-decoration: underline;
  color: var(--blue);

  cursor: pointer;
}

.deploy-detail__add-release-wrapper {
  display: flex;
  justify-content: flex-end;
}

.deploy-detail__add-release {
  width: auto;
}
</style>
